<template>
  <div>
    <div class="uk-card uk-card-default uk-padding-small uk-border-rounded">
      <div class="uk-flex uk-flex-between">
        <div class="uk-text-large">Mi perfil</div>
        <button
          class="uk-button uk-button-default"
          uk-icon="pencil"
          href="#edit-user-modal"
          uk-toggle
          @click="setEditMode"
        ></button>
      </div>
      <div class="uk-text-bold uk-margin-small-top">Nombre</div>
      <div>{{ authUser.first_name }} {{ authUser.last_name }}</div>
      <div class="uk-text-bold uk-margin-small-top">Email</div>
      <div>{{ authUser.email }}</div>

      <div v-if="authUser.job_title">
        <div class="uk-text-bold uk-margin-small-top">Puesto</div>
        <div>{{ authUser.job_title }}</div>
      </div>

      <div v-if="authUser.phone">
        <div class="uk-text-bold uk-margin-small-top">Teléfono</div>
        <div>{{ authUser.phone }}</div>
      </div>

      <div v-if="authUser.mobile_phone">
        <div class="uk-text-bold uk-margin-small-top">Teléfono móvil</div>
        <div>{{ authUser.mobile_phone }}</div>
      </div>

      <div class="uk-text-bold uk-margin-small-top">
        Notificaciones de cambios de stock o precios de artículos en carrito
      </div>
      <div v-if="authUser.cart_notifications">Activadas</div>
      <div v-else>Desactivadas</div>
    </div>

    <div id="edit-user-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="
          uk-modal-dialog
          uk-modal-body
          uk-margin-auto-vertical
          uk-border-rounded
          uk-overflow-hidden
        "
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">Editar perfil</div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombres"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Nombres</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombres"
              v-model="model.first_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Apellidos"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Apellidos</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Apellidos"
              v-model="model.last_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Puesto" v-slot="{ errors }">
            <div class="uk-margin-top">Puesto</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Puesto"
              v-model="model.job_title"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Email</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Email"
              v-model="model.email"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Teléfono" v-slot="{ errors }">
            <div class="uk-margin-top">Teléfono</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono"
              v-model="model.phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Teléfono móvil" v-slot="{ errors }">
            <div class="uk-margin-top">Teléfono móvil</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono móvil"
              v-model="model.mobile_phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="type" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="model.cart_notifications"
                />
                Notificaciones de cambios de stock o precios de artículos en
                carrito
              </label>
              <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
            </div>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="update"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else> Actualizar </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <AddressCrud />
  </div>
</template>

<script>
import UIkit from "uikit";
import AddressCrud from "../Utils/AddressCrud";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileIndex",

  components: {
    AddressCrud,
  },

  data() {
    return {
      mode: "new",
      model: {},
      isLoadingAddresses: false,
      salesmen: [],
      isLoadingSalesmen: false,
      selectedAddress: null,
      isFetching: false,
      isLoading: false,
    };
  },

  mounted() {
    this.resetModel();
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  methods: {
    ...mapActions(["authFetchUser"]),
    resetModel() {
      this.model = {
        first_name: this.authUser.first_name,
        last_name: this.authUser.last_name,
        email: this.authUser.email,
        phone: this.authUser.phone,
        mobile_phone: this.authUser.mobile_phone,
        job_title: this.authUser.job_title,
        cart_notifications: this.authUser.cart_notifications,
      };
    },
    setEditMode() {
      this.resetModel();
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/user", this.model)
        .then(() => {
          this.authFetchUser();
          UIkit.modal("#edit-user-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
