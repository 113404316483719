<template>
  <div class="uk-border-rounded uk-overflow-hidden">
    <div :ref="'googleMap-' + id" class="google-map"></div>

    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

const apiKey = "AIzaSyDAar11F6SeaHoRldAeQvAb7j2CH8vUyJg";

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export default {
  name: "GoogleMaps",

  props: {
    mapConfig: Object,
    editable: Boolean,
    value: Object,
  },

  data() {
    return {
      id: uuidv4(),
      map: null,
      google: null,
      model: {
        lat:
          this.editable && this.value && this.value.lat ? this.value.lat : 14.5,
        lng:
          this.editable && this.value && this.value.lng
            ? this.value.lng
            : -90.5,
      },
      editableMarker: null,
      editableMapConfig: {
        center: {
          lat: 14.5,
          lng: -90.5,
        },
        zoom: 13,
      },
    };
  },

  mounted() {
    new Loader({
      apiKey,
    })
      .load()
      .then((google) => {
        this.google = google;
        this.initializeMap();
      });
  },

  methods: {
    initializeMap() {
      this.map = new this.google.maps.Map(
        this.$refs["googleMap-" + this.id],
        this.editable ? this.editableMapConfig : this.mapConfig
      );

      if (this.editable) {
        this.updateEditableMarker(this.model.lat, this.model.lng);

        this.map.addListener("click", (event) => {
          let position = event.latLng.toJSON();
          this.updateEditableMarker(position.lat, position.lng);
        });
      }
    },

    updateEditableMarker(lat, lng) {
      this.model.lat = lat;
      this.model.lng = lng;

      if (this.editableMarker) {
        this.editableMarker.setMap(null);
      }
      this.editableMarker = new this.google.maps.Marker({
        position: { lat: this.model.lat, lng: this.model.lng },
        marker: {
          id: this.id + "-editableMarker",
          position: {
            lat: this.model.lat,
            lng: this.model.lng,
          },
        },
        map: this.map,
      });

      this.map.setCenter(this.editableMarker.getPosition());

      this.$emit("input", this.model);
    },
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.updateEditableMarker(value.lat ?? 14.5, value.lng ?? -90.5);
      },
    },
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 300px;
}
</style>